const BridgeIcon = ({ width, height }: { width?: number; height?: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="100" height="100" rx="10" fill="#B4F132" />
    <path
      d="M11 54C11 51.7909 12.7909 50 15 50H37V58C37 60.2091 35.2091 62 33 62H15C12.7909 62 11 60.2091 11 58V54Z"
      fill="#060606"
    />
    <path
      d="M63 50H85C87.2091 50 89 51.7909 89 54V58C89 60.2091 87.2091 62 85 62H67C64.7909 62 63 60.2091 63 58V50Z"
      fill="#060606"
    />
    <path
      d="M37 42C37 39.7909 38.7909 38 41 38H59C61.2091 38 63 39.7909 63 42V50H37V42Z"
      fill="#060606"
    />
  </svg>
);

export default BridgeIcon;
