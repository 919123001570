import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Skeleton } from "@/components/ui/skeleton";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronRight, Landmark } from "lucide-react";
import { RouterOutput, trpc } from "../../utils/trpc";
import { useConnectDispatch, useConnectState } from "../ConnectContext";
import ConnectStep from "../components/ConnectStep";
import { ActionType } from "../types";

const InstitutionSelectStep = () => {
  const { connectSessionId } = useConnectState();
  const institutionsQuery = trpc.connect.getInstitutions.useQuery({
    connectSessionId: connectSessionId!,
  });

  const dispatch = useConnectDispatch();

  const handleSelectInstitution = (
    institution: RouterOutput["connect"]["getInstitutions"]["institutions"][0]
  ) => {
    dispatch({
      type: ActionType.SetInstitution,
      payload: {
        id: institution.id,
        name: institution.name,
        url: institution.domain || "",
        iconUrl: institution.iconUrl || undefined,
        authFields: institution.authFields,
      },
    });
  };

  return (
    <ConnectStep title="Selecciona tu banco" noContainerPadding>
      <div className="flex flex-col h-full pt-2">
        <AnimatePresence mode="wait">
          <motion.div
            key={institutionsQuery.isLoading ? "loading" : "loaded"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {institutionsQuery.isLoading &&
              [1, 2, 3].map((i) => (
                <div key={i} className="flex flex-col gap-2 px-3">
                  <div className="flex items-center gap-3 p-2">
                    <Skeleton className="h-14 w-14 rounded-lg" />
                    <div className="grid gap-1">
                      <Skeleton className="h-4 w-40 rounded-lg" />
                      <Skeleton className="h-4 w-32 rounded-lg" />
                    </div>
                  </div>
                </div>
              ))}
            {!institutionsQuery.isLoading && (
              <>
                <div className="flex flex-col overflow-auto px-3">
                  {institutionsQuery.data?.institutions.map((ins) => (
                    <div
                      className="cursor-pointer hover:bg-muted rounded-lg"
                      key={ins.id}
                      onClick={() => handleSelectInstitution(ins)}
                    >
                      <div className="flex items-center gap-3 p-2">
                        <Avatar className="h-12 w-12 rounded-md border">
                          <AvatarImage src={ins.iconUrl || ""} />
                          <AvatarFallback className="rounded-md">
                            <Landmark />
                          </AvatarFallback>
                        </Avatar>
                        <div className="grid gap-1">
                          <p className="text-md font-medium leading-tight">
                            {ins.name}
                          </p>
                          <p className="text-xs text-muted-foreground">
                            {ins.domain}
                          </p>
                        </div>
                        <ChevronRight className="ml-auto w-5 h-5 text-slate-400" />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </motion.div>
        </AnimatePresence>

        <div className="mt-auto text-muted-foreground text-xs text-center p-3">
          Todas las marcas registradas son propiedad de sus respectivos dueños.
          Su uso no representa un endorso ni afiliación con Bridge.
        </div>
      </div>
    </ConnectStep>
  );
};

export default InstitutionSelectStep;
