import { FallbackRender } from "@sentry/react";
import { XCircle } from "lucide-react";
import { useConnectDispatch } from "../ConnectContext";
import { sendExit } from "../messages";
import { ActionType } from "../types";
import ConnectStep from "./ConnectStep";

const UnexpectedErrorFallback: FallbackRender = ({ resetError }) => {
  const dispatch = useConnectDispatch();
  const handleClose = () => {
    sendExit();
    setTimeout(() => {
      dispatch({ type: ActionType.Reset });
      resetError();
    }, 0);
  };

  return (
    <ConnectStep nextButtonLabel="Cerrar" onClickNext={handleClose}>
      <div className="flex h-full flex-col justify-center">
        <div className="flex flex-col gap-2 p-4">
          <div>
            <XCircle className="w-16 h-16 text-red-500" />
            <h1 className="text-2xl mt-4 font-semibold leading-none tracking-tight">
              Error
            </h1>
          </div>

          <p className="text-sm text-muted-foreground">
            Ha ocurrido un error inesperado. Por favor, intenta de nuevo más
            tarde.
          </p>
        </div>
      </div>
    </ConnectStep>
  );
};

export default UnexpectedErrorFallback;
