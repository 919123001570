import { Alert, AlertDescription } from "@/components/ui/alert";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { trpc } from "@/utils/trpc";
import { AnimatePresence, motion } from "framer-motion";
import { Landmark, Verified } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useConnectDispatch, useConnectState } from "../ConnectContext";
import ConnectStep from "../components/ConnectStep";
import { ActionType } from "../types";

const AuthStep = () => {
  const authenticateMutation = trpc.connect.authenticate.useMutation();
  const verifyMutation = trpc.connect.verify.useMutation();

  const { institution, connectSessionId } = useConnectState();
  const dispatch = useConnectDispatch();

  const [stage, setStage] = useState<"auth" | "verify">("auth");
  const [fieldSpecs, setFieldSpecs] = useState(institution!.authFields);
  const [hasCredentialsError, setHasCredentialsError] =
    useState<boolean>(false);

  const makeDefaultValues = () => {
    const values: Record<string, string> = {};
    fieldSpecs.forEach((field) => {
      values[field.key] = "";
    });
    return values;
  };

  const form = useForm({
    defaultValues: makeDefaultValues(),
  });

  const handleAuthSubmit = async (data: Record<string, string>) => {
    const authRes = await authenticateMutation.mutateAsync({
      connectSessionId: connectSessionId!,
      institutionId: institution!.id,
      credentials: data,
    });

    if (!authRes.success) {
      return setHasCredentialsError(true);
    }

    setHasCredentialsError(false);

    if (authRes.verificationRequired) {
      if (!authRes.verificationFields) {
        return console.error("verificationFields not found");
      }

      form.reset(makeDefaultValues());
      setFieldSpecs(authRes.verificationFields);
      setStage("verify");
    } else {
      dispatch({
        type: ActionType.SetAuthenticated,
        payload: {
          needsVerification: false,
        },
      });
    }
  };

  const handleVerifySubmit = async (data: Record<string, string>) => {
    const verifyRes = await verifyMutation.mutateAsync({
      connectSessionId: connectSessionId!,
      payload: data,
    });

    if (!verifyRes.success) {
      return setHasCredentialsError(true);
    }

    dispatch({
      type: ActionType.SetVerified,
      payload: {
        verified: true,
      },
    });
  };

  const onSubmit = async (data: Record<string, string>) => {
    if (stage === "auth") {
      return handleAuthSubmit(data);
    } else if (stage === "verify") {
      return handleVerifySubmit(data);
    }
  };

  return (
    <ConnectStep
      nextButtonLabel="Ingresar"
      nextLoading={authenticateMutation.isLoading || verifyMutation.isLoading}
      onClickNext={form.handleSubmit(onSubmit)}
    >
      <Avatar className="h-14 w-14 rounded-md border">
        <AvatarImage src={institution?.iconUrl || ""} />
        <AvatarFallback className="rounded-md">
          <Landmark />
        </AvatarFallback>
      </Avatar>

      <p className="text-2xl mt-2">
        Ingresa a <span className="font-medium">{institution?.name}</span>
      </p>
      <p className="text-sm text-muted-foreground flex items-center">
        {institution?.url} <Verified className="w-4 h-4 pl-1" />
      </p>

      {(authenticateMutation.isError || verifyMutation.isError) && (
        <Alert className="mt-4" variant="destructive">
          <AlertDescription>
            Un error inesperado ha occurrido. Por favor, intente mas tarde.
          </AlertDescription>
        </Alert>
      )}

      <AnimatePresence mode="wait">
        <motion.div
          key={stage}
          initial={{ x: stage === "verify" ? 10 : 0, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{
            x: -10,
            opacity: 0,
          }}
          transition={{ duration: 0.1 }}
        >
          {hasCredentialsError && (
            <Alert className="mt-4" variant="destructive">
              <AlertDescription>
                Ocurrió un error al ingresar a {institution?.name}. Revise sus
                credenciales e intente de nuevo.
              </AlertDescription>
            </Alert>
          )}
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-4 mt-4"
            >
              {fieldSpecs.map((fieldSpec) => (
                <FormField
                  key={fieldSpec.key}
                  control={form.control}
                  name={fieldSpec.key}
                  rules={{ required: fieldSpec.required }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{fieldSpec.label}</FormLabel>
                      <FormControl>
                        <Input
                          className="text-base"
                          autoCorrect="off"
                          autoCapitalize="none"
                          type={fieldSpec.type}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}
              <input type="submit" hidden />
            </form>
          </Form>
        </motion.div>
      </AnimatePresence>
    </ConnectStep>
  );
};

export default AuthStep;
