import React from "react";
import ReactDOM from "react-dom/client";
import Connect from "./connect/Connect.tsx";
import { ConnectProvider } from "./connect/ConnectContext.tsx";
import "./index.css";
import "./sentry.ts";
import TRPCProvider from "./utils/TRPCProvider.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <TRPCProvider>
      <ConnectProvider>
        <Connect />
      </ConnectProvider>
    </TRPCProvider>
  </React.StrictMode>
);
