import { trpc } from "@/utils/trpc";
import {
  CheckCircle2,
  Ellipsis,
  FlaskConical,
  Landmark,
  LoaderCircle,
  Lock,
  LucideIcon,
  Smartphone,
} from "lucide-react";
import { useConnectDispatch } from "../ConnectContext";
import BridgeIcon from "../components/BridgeIcon";
import ConnectStep from "../components/ConnectStep";
import { ActionType } from "../types";

const ConsentItem = ({
  icon: Icon,
  title,
  description,
  className,
}: {
  icon: LucideIcon;
  title: string;
  description: string | React.ReactNode;
  className?: string;
}) => (
  <div className={`flex flex-row gap-2 ${className}`}>
    <div className="mt-1">
      <Icon className="w-4 h-4 text-slate-400 " />
    </div>
    <div className="text-sm">
      <div className="font-medium">{title}</div>
      <div className="text-muted-foreground">{description}</div>
    </div>
  </div>
);

const InitStep = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const applicationId = urlParams.get("applicationId");
  const appConfig = trpc.connect.getApplicationConfig.useQuery(
    { applicationId: applicationId as string },
    {
      enabled: !!applicationId,
    }
  );
  const createSessionMutation = trpc.connect.createSession.useMutation();
  const dispatch = useConnectDispatch();

  const handleClickNext = async () => {
    if (!applicationId) {
      console.warn("No applicationId provided");
      return;
    }

    const { connectSessionId } = await createSessionMutation.mutateAsync({
      applicationId,
    });

    dispatch({
      type: ActionType.InitializeSession,
      payload: {
        applicationId,
        appConfig: appConfig.data!,
        sessionId: connectSessionId,
      },
    });
  };

  const isInitializing = appConfig.isLoading || appConfig.isError;

  return (
    <ConnectStep
      nextButtonLabel={!isInitializing ? "Continuar" : undefined}
      nextLoading={createSessionMutation.isLoading}
      onClickNext={handleClickNext}
    >
      {isInitializing && (
        <div className="flex items-center justify-center my-5">
          <LoaderCircle className="animate-spin h-10 w-10 text-muted-gray" />
        </div>
      )}

      {!isInitializing && (
        <div className="h-full flex space-y-6 flex-col">
          <div className="flex flex-row items-center justify-center">
            <div className="w-10 h-10 rounded-xl bg-muted flex items-center justify-center shadow border border-background">
              <Smartphone className="h-5 w-5 text-slate-400" />
            </div>
            <Ellipsis className="text-slate-400" />
            <div className="overflow-hidden w-14 h-14 rounded-xl bg-muted flex items-center justify-center shadow border border-background">
              <BridgeIcon width={100} height={100} />
            </div>
            <Ellipsis className="text-slate-400" />
            <div className="w-10 h-10 rounded-xl bg-muted flex items-center justify-center shadow border border-background">
              <Landmark className="h-5 w-5 text-slate-400" />
            </div>
          </div>

          <h1 className="text-2xl font-semibold text-center">
            <span>{appConfig.data.name}</span> utiliza <span>Bridge</span> para
            conectar tus cuentas bancarias
          </h1>

          <div className="flex flex-1 flex-col gap-3 rounded-lg">
            <ConsentItem
              className="px-3"
              icon={CheckCircle2}
              title="Simple y rápido"
              description="Conecta tus cuentas en segundos."
            />

            <ConsentItem
              className="px-3"
              icon={Lock}
              title="Tus datos son tuyos"
              description={
                <>
                  Bridge no vende tus datos personales y solo compartirá los
                  datos solicitados a esta aplicación ({appConfig.data.name}
                  ).
                </>
              }
            />

            {appConfig.data.isSandbox && (
              <ConsentItem
                icon={FlaskConical}
                title="Modo de prueba habilitado"
                description="Simula escenarios de prueba sin utilizar credenciales reales."
                className="bg-muted p-3 rounded-lg text-warning"
              />
            )}
          </div>
        </div>
      )}
    </ConnectStep>
  );
};

export default InitStep;
