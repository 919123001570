import { CircleCheck } from "lucide-react";
import { useConnectDispatch, useConnectState } from "../ConnectContext";
import ConnectStep from "../components/ConnectStep";
import { sendSuccess } from "../messages";
import { ActionType } from "../types";

const FinishedStep = () => {
  const { institution, connectSessionId, accounts, appConfig } =
    useConnectState();
  const dispatch = useConnectDispatch();

  const handleFinish = () => {
    if (!connectSessionId || !accounts?.selected || !institution)
      throw Error("Missing data");
    sendSuccess(connectSessionId, accounts.selected, institution);
    setTimeout(() => {
      dispatch({ type: ActionType.Reset });
    }, 0);
  };

  return (
    <ConnectStep nextButtonLabel="Finalizar" onClickNext={handleFinish}>
      <div className="flex flex-col align-center justify-center h-full items-center">
        <div className="flex flex-col gap-2 p-4">
          <div>
            <CircleCheck className="w-16 h-16 text-green-500" />
            <h1 className="text-2xl mt-4 font-semibold leading-none tracking-tight">
              Conexión exitosa
            </h1>
          </div>

          <p className="text-sm text-muted-foreground">
            Has conectado{" "}
            <span className="font-medium">
              {accounts?.selected?.length || 0}
            </span>{" "}
            cuenta(s) de{" "}
            <span className="font-medium">{institution?.name}</span> a{" "}
            {appConfig?.name}.
          </p>
        </div>
      </div>
    </ConnectStep>
  );
};

export default FinishedStep;
