import { AccountDto } from "@bridge/server/src/api/accounts/dto/account.dto";

export const sendExit = () => {
  window.parent?.postMessage({ source: "bridge-connect", type: "exit" }, "*");
};

export const sendSuccess = (
  sessionId: string,
  selectedAccounts: AccountDto[],
  institution: { id: string; name: string }
) => {
  window.parent?.postMessage(
    {
      source: "bridge-connect",
      type: "success",
      payload: {
        sessionId: sessionId,
        institution: {
          id: institution?.id,
          name: institution?.name,
        },
        accounts: selectedAccounts,
      },
    },
    "*"
  );
};
