import { Skeleton } from "@/components/ui/skeleton";
import { trpc } from "@/utils/trpc";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

import { useConnectDispatch, useConnectState } from "../ConnectContext";
import AccountSelectItem from "../components/AccountSelectItem";
import ConnectStep from "../components/ConnectStep";
import { ActionType } from "../types";

const AccountSelectStep = () => {
  const { institution, connectSessionId } = useConnectState();
  const dispatch = useConnectDispatch();
  const accountsQuery = trpc.connect.accounts.useQuery({
    connectSessionId: connectSessionId!,
  });
  const selectAccountsMutation = trpc.connect.grantAccess.useMutation();

  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);

  const onClickAccount = (id: string) => {
    if (selectedAccountIds.includes(id)) {
      setSelectedAccountIds(selectedAccountIds.filter((selId) => selId !== id));
    } else {
      setSelectedAccountIds([...selectedAccountIds, id]);
    }
  };

  const handleClickNext = async () => {
    if (!accountsQuery.data) return;

    const { success } = await selectAccountsMutation.mutateAsync({
      connectSessionId: connectSessionId!,
      accountIds: selectedAccountIds,
    });

    if (success) {
      const selectedAccounts = accountsQuery.data.accounts.filter((a) =>
        selectedAccountIds.includes(a.id)
      );

      dispatch({
        type: ActionType.SetSelectedAccounts,
        payload: {
          accounts: selectedAccounts,
        },
      });
    }
  };

  return (
    <ConnectStep
      title="Selecciona tus cuentas"
      description={
        <>
          Selecciona las cuentas de{" "}
          <span className="font-medium">{institution?.name}</span> que deseas
          conectar a la aplicación.
        </>
      }
      nextLoading={selectAccountsMutation.isLoading}
      nextButtonLabel="Seleccionar"
      nextButtonDisabled={selectedAccountIds.length === 0}
      onClickNext={handleClickNext}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={accountsQuery.isLoading ? "loading" : "loaded"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {accountsQuery.isLoading && (
            <div className="flex flex-col gap-2 my-2">
              <Skeleton className="h-20 rounded-lg" />
              <Skeleton className="h-20 rounded-lg" />
              <Skeleton className="h-20 rounded-lg" />
            </div>
          )}
          {accountsQuery.data?.accounts.map((account) => (
            <AccountSelectItem
              key={account.id}
              selected={selectedAccountIds.includes(account.id)}
              onClick={() => onClickAccount(account.id)}
              name={account.name}
              type={account.type}
              accountNumber={account.lastFour}
              currency={account.currency}
              balance={account.balance.current}
            />
          ))}
        </motion.div>
      </AnimatePresence>
    </ConnectStep>
  );
};

export default AccountSelectStep;
