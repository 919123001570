import { Badge } from "@/components/ui/badge";
import { Switch } from "@/components/ui/switch";
import { Banknote, CreditCard, Ellipsis } from "lucide-react";
import React from "react";

interface AccountSelectItemProps {
  type: string;
  name: string;
  accountNumber: string;
  currency: string;
  balance: number;
  selected?: boolean;
  onClick?: () => void;
}

const formatMoney = (amount: number) => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

const AccountSelectItem: React.FC<AccountSelectItemProps> = ({
  type,
  name,
  accountNumber,
  currency,
  balance,
  selected,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={
        "flex items-center space-x-4 my-2 rounded-lg border-2 p-3 cursor-pointer" +
        (selected ? " border-primary" : "")
      }
    >
      <div className="flex-1 space-y-1">
        <div className="text-sm font-medium leading-none flex flex-row items-center">
          {type === "credit" && <CreditCard className="mr-1.5 w-5 h-5" />}
          {type === "depository" && <Banknote className="mr-1.5 w-5 h-5" />}
          {name}
        </div>
        <div className="text-sm text-muted-foreground pt-1">
          <span className="flex flex-row items-center space-x-1">
            <Badge variant="outline" className="text-muted-foreground">
              <Ellipsis className="w-4 h-4" /> {accountNumber}
            </Badge>
            <Badge variant="outline" className="text-muted-foreground">
              {currency} ${formatMoney(balance)}
            </Badge>
          </span>
        </div>
      </div>
      <Switch checked={selected} />
    </div>
  );
};

export default AccountSelectItem;
